body {
  font-family: 'Montserrat', sans-serif !important;
}
.sitecontainer {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.border-dashed {
  border-bottom-style: dashed !important;
}
.color-GrayLight {
  color: #dcdcdc !important;
}
.color-GrayDark {
  color: #575757 !important;
}
.color-primary {
  color: #575757 !important;
}
.color-secondary {
  color: #428ba9 !important;
}
.link-GrayLight {
  color: #575757 !important;
}
.link-GrayLight:hover {
  text-decoration: none;
  color: #428ba9 !important;
}
.btn-color-primary {
  background-color: #575757 !important;
  color: white !important;
}
.btn-color-secondary {
  background-color: #428ba9 !important;
  color: white !important;
}
.btn-color-GrayLight {
  background-color: #dcdcdc !important;
  color: #575757 !important;
}
.loading-primary {
  background-repeat: no-repeat;
  background-position: 5px 3px;
  padding-left: 40px;
  background-image: url("/images/ajax/loaderyggreen.gif") !important;
}
.loading-secondary {
  background-repeat: no-repeat;
  background-position: 5px 3px;
  padding-left: 40px;
  background-image: url("/images/ajax/loaderyg.gif") !important;
}
a.link-GrayDark {
  color: #575757 !important;
}
header {
  /*.headerservicebargreen {
        background-color: @colorPrimary;
        height: 45px;
        color: white;
        font-family: @font2;
        font-size: 18px;




        a {
            color: white;
            margin-right: 10px;
            margin-left: 10px;

            &:hover {
                color: @colorSecondary;
                text-decoration: none;
            }
        }
    }*/
}
header .header {
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 5px;
  background-color: white;
}
header .headerservicebar {
  background-color: #dcdcdc;
  color: #575757;
  height: 40px;
  font-family: 'Exo 2', sans-serif;
  font-size: 18px;
}
header .headerservicebar a {
  color: #575757 !important;
  margin-right: 10px;
  margin-left: 10px;
}
header .headerservicebar a:hover {
  color: #575757 !important;
  text-decoration: none;
}
header .cartheadercomponentyg {
  display: inline-block;
  padding-left: 33px;
  font-size: 14px !important;
  background-image: url("/images/Common/icomin_cart.png");
  background-repeat: no-repeat;
  background-position: 0 -3px;
  color: #575757 !important;
  text-decoration: none;
  min-height: 17px;
}
header .headerbtlogin {
  display: inline-block;
  padding-left: 33px;
  font-size: 14px !important;
  background-image: url("/images/Common/icomin_user.png");
  background-repeat: no-repeat;
  background-position: 0 -3px;
  color: #575757 !important;
  text-decoration: none;
  margin-right: 15px;
}
header .headerbtregister {
  display: inline-block;
  padding-left: 33px;
  font-size: 14px !important;
  background-image: url("/images/Common/icomin_register.png");
  background-repeat: no-repeat;
  background-position: 0 -3px;
  color: #575757 !important;
  text-decoration: none;
  margin-right: 15px;
}
header .headerbtlogout {
  display: inline-block;
  padding-left: 33px;
  font-size: 14px !important;
  background-image: url("/images/Common/icomin_logout.png");
  background-repeat: no-repeat;
  background-position: 0 -3px;
  color: #575757 !important;
  text-decoration: none;
  margin-right: 15px;
}
header .headerbtordini {
  display: inline-block;
  padding-left: 33px;
  font-size: 14px !important;
  background-image: url("/images/Common/icomin_ordini.png");
  background-repeat: no-repeat;
  background-position: 0 -3px;
  color: #575757 !important;
  text-decoration: none;
  margin-right: 15px;
}
header .headerbttelephone {
  display: inline-block;
  padding-left: 33px;
  font-size: 18px !important;
  background-image: url("/images/Common/icomin_telefono.png");
  background-repeat: no-repeat;
  background-position: 0 0px;
  color: #575757 !important;
  text-decoration: none;
}
header .topphonetext {
  font-size: 20px;
}
@media screen and (max-width: 780px) {
  header .text-title-mobile {
    font-size: 0.7em !important;
  }
}
header .sectionimagewrapper {
  position: relative;
  width: 100%;
  height: 400px;
}
header .sectionimagewrapper .sectionimage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  /*display:none;*/
  background-position-x: center;
  background-color: #f8f8f8;
  height: 400px;
  margin-top: 5px;
}
header .sectionimagewrappermobile {
  position: relative;
  width: 100%;
  height: 150px;
}
header .sectionimagewrappermobile .sectionimage {
  background-size: cover !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  /*display:none;*/
  background-position-x: center;
  background-color: #f8f8f8;
  height: 150px;
  margin-top: 5px;
}
.badge-yougadget {
  background-color: #428ba9;
  color: white;
}
/***********************    CATEGORIE   *******************************/
.btcategoriemobile {
  background-image: url("/images/Common/hamburgermenu.png");
  display: inline-block;
  padding-left: 30px;
  background-repeat: no-repeat;
}
.menucategoryline {
  /*margin-bottom: 10px;*/
  border-bottom: solid 1px #dcdcdc;
  padding: 5px 0 5px 0;
}
.menucategoryline a {
  color: #575757;
}
.menucategoryline a:hover {
  text-decoration: none;
}
.menucategoryline .menucategoryname {
  padding: 10px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menucategoryline .btnToggleCategories {
  display: inline-block;
  background-color: #fff;
  border: solid 1px #dcdcdc;
  color: #575757 !important;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 40px;
  height: 40px;
  padding-top: 3px;
  /*padding: 10px;
        width: 40px;
        height: 40px;*/
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.menucategoryline .btnToggleCategories:hover {
  background-color: #575757;
  color: white !important;
}
.menucategoryline .btnToggleCategories::after {
  content: '+';
}
.menucategoryline .btnToggleCategoriesActive {
  background-color: #dcdcdc;
}
.menucategoryline .btnToggleCategoriesActive::after {
  content: '-' !important;
}
.menucategoryline[data-level="0"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.menucategoryline:not([data-level="0"]) a {
  color: #8a8a8a !important;
}
.menubtloadfull {
  background-color: gray;
  margin-bottom: 30px;
}
.menubtloadfull .menubtname {
  padding: 10px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menubtloadfull .menubtname a {
  color: white !important;
  cursor: pointer;
}
.menubtloadfull .menubtname a:hover {
  text-decoration: none;
}
.menubtloadfull .btnToggleMenuFull {
  display: inline-block;
  background-color: #fff;
  border: solid 1px #dcdcdc;
  color: #575757 !important;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 40px;
  height: 40px;
  padding-top: 3px;
  /*padding: 10px;
        width: 40px;
        height: 40px;*/
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.menubtloadfull .btnToggleMenuFull:hover {
  background-color: #575757;
  color: white !important;
}
.menubtloadfull .btnToggleMenuFull::after {
  content: '+';
}
.menubtloadfull .btnToggleMenuFullActive::after {
  content: '-';
}
.accordion {
  display: none;
}
.sectiontag {
  /*background: @colorSecondary;*/
  color: #428ba9;
  display: inline-block;
  min-height: 50px;
  padding: 10px 0px 10px 0px;
  position: relative;
  margin: 10px 0 0 0;
  font-size: 20px;
  font-family: 'Exo 2', sans-serif;
  /*&:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-top: 50px solid #fff;
        border-left: 50px solid transparent;
        width: 0;
    }*/
}
@media screen and (max-width: 780px) {
  .sectiontag {
    padding: 15px 0px 10px 0px;
    font-size: 13px;
  }
}
.sectiontag + hr {
  margin: 0 0 10px 0;
}
.sectiontagdescription {
  font-size: 14px;
  text-align: justify;
}
.imgpoptaglie {
  position: absolute;
  padding: 5px;
  background-color: white;
  left: 0;
  width: 200px;
  height: 200px;
  top: 0;
  -webkit-transform: translate(-105%, -100px);
          transform: translate(-105%, -100px);
  border: solid 1px #dcdcdc;
  border-radius: 5px;
  display: none;
}
.trimgcolore:hover .imgpoptaglie {
  display: block;
}
.cardprodottocolumn {
  border-right: solid 1px #eeeeee;
  margin-bottom: 40px;
}
.cardprodottocolumn hr {
  color: #eeeeee;
}
.cardprodottocolumn .cardprodotto {
  padding: 5px;
  position: relative;
}
.cardprodottocolumn .cardprodotto .labelwrapper {
  top: 5px;
  left: 5px;
  position: absolute;
}
.cardprodottocolumn .cardprodotto .labelwrapper .label {
  padding: 3px 10px 3px 10px;
  display: inline-block;
  margin-left: 5px;
  font-size: 11px;
  border: solid 1px #dcdcdc;
  border-radius: 3px;
  font-family: 'Exo 2', sans-serif;
  text-transform: uppercase;
}
.cardprodottocolumn .cardprodotto img {
  margin-bottom: 15px;
  /*border:solid 1px #fff;*/
}
.cardprodottocolumn .cardprodotto img:hover {
  /*border-color:@colorPrimary;*/
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottotitle {
  font-weight: bold;
  font-size: 14px !important;
  margin-bottom: 15px;
  height: 25px;
  /*Clamp column*/
  display: -webkit-box;
  height: 35px;
  line-height: 1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottotitle a {
  color: #575757;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottotitle a:hover {
  color: #575757;
  text-decoration: none;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottocodice {
  color: #575757;
  font-size: 11px;
  margin: 10px 0 10px 0;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottotext {
  color: #575757;
  text-align: justify;
  font-size: 12px;
  margin-bottom: 20px;
  /*Clamp column*/
  display: -webkit-box;
  height: 85px;
  margin: 0 auto;
  line-height: 1.2;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottoprezzo {
  color: #428ba9;
  font-family: 'Anton', sans-serif;
  font-size: 32px;
  margin-top: 25px;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottocolori {
  min-height: 40px;
}
.cardprodottocolumn .cardprodotto .cardprodottobody .cardprodottoimgcolore {
  height: 15px;
  width: 15px;
  display: inline-block;
  border: solid 1px #dcdcdc;
  margin: 0 3px 3px 0;
}
.listsearchbar {
  /*background-color:@colorGrayVeryLight;*/
  border-bottom: solid 1px #dcdcdc;
  padding: 10px;
}
.schedaprodottowrapper {
  /*Aspetto opzioni personalizzazione*/
}
.schedaprodottowrapper .titlescheda {
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  background-color: #ededed;
  padding: 10px;
}
.schedaprodottowrapper .boxgrayyougadget {
  background-color: #ededed;
  padding: 10px;
}
.schedaprodottowrapper .boxgrayyougadget .boxgrayyougadgetitem {
  border-bottom: solid 1px #ced2d6;
}
.schedaprodottowrapper .boxgrayyougadget .boxgrayyougadgetitem:last-child {
  border: none;
}
.schedaprodottowrapper .codicescheda {
  color: gray;
}
.schedaprodottowrapper .imgbigwrapper {
  position: relative;
}
.schedaprodottowrapper .imgbigwrapper .labelwrapper {
  position: absolute;
  top: 5px;
  left: 5px;
}
.schedaprodottowrapper .imgbigwrapper .labelwrapper .label {
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  margin-left: 10px;
  display: inline-block;
  text-transform: uppercase;
}
.schedaprodottowrapper .optioncontainer {
  background-color: #ededed;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
}
.schedaprodottowrapper .optioncontainer .optioncontainertitle {
  font-weight: bold;
  color: #428ba9;
  border-bottom: solid 2px white;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
.table-default th {
  background-color: #eeeeee;
  color: #575757;
  padding: 5px 10px 5px 10px;
}
.table-default td {
  padding: 5px 10px 5px 10px;
  color: #575757;
  font-size: 13px;
}
.table-default .imgtagliecolore {
  width: 25px;
  display: inline-block;
  margin-right: 20px;
  border: solid 1px #eeeeee;
}
.table-default .rowdetail {
  display: none;
}
.prezzobig {
  font-size: 34px;
  font-family: 'Anton', sans-serif;
  color: #428ba9;
}
.prezzomid {
  font-size: 26px;
  font-family: 'Anton', sans-serif;
  color: #428ba9;
}
.tbqtatotale {
  /*font-size: 18px;*/
}
.btn-addcart {
  padding: 15px 15px 15px 65px;
  background-image: url('/images/Common/btcartmin.png');
  background-repeat: no-repeat;
  color: white !important;
  background-color: #428ba9;
  background-position: 10px;
}
.table-cart td {
  border: none !important;
  border-bottom: solid 1px #dcdcdc !important;
}
.table-cart td img {
  max-width: 100px;
}
.table-cart th {
  border-top: none;
}
.emptybox {
  width: 100%;
  padding: 20px;
  text-align: center;
  border: dashed 1px #dcdcdc;
}
.cartimgcolore {
  height: 15px;
  width: 15px;
  display: inline-block;
  border: solid 1px #dcdcdc;
  margin: 0 15px 3px 0;
}
.couponprodottocartline {
  border: solid 1px #eeeeee;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  margin-top: 10px;
  color: #428ba9;
  display: inline-block;
}
.sectionsmalltitle {
  margin-top: 30px;
  font-size: 16px !important;
  padding-bottom: 10px;
  border-bottom: solid 1px #428ba9;
  margin-bottom: 20px;
  font-weight: bold;
}
.footer {
  background-color: #eeeeee;
  margin-top: 50px;
  padding-top: 30px;
}
.footer a {
  color: #575757 !important;
  text-decoration: none;
}
.footer a:hover {
  color: #428ba9 !important;
}
.footer .verybig {
  font-family: 'Anton', sans-serif;
  font-size: 33px;
  font-weight: bold;
}
.btn-facebook {
  padding-left: 80px;
  background-image: url("/images/Common/fblight.jpg");
  background-repeat: no-repeat;
  height: 60px;
  padding-top: 18px;
}
.btcategorymobile {
  color: white;
  padding: 10px;
  font-weight: bold;
  background-color: #575757;
  border-bottom: solid 1px #dcdcdc;
  background-position: 10px 7px;
  background-repeat: no-repeat;
  background-image: url("/images/Common/hamburgerwhite.png");
}
.menucategorymobile {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: white;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
}
.menumobileclosebar {
  padding: 10px 25px 10px 25px;
  background-color: #eeeeee;
}
.menucategorymobilecontainer {
  padding: 25px;
  overflow-y: auto;
  height: 100%;
}
.testodisponibile {
  color: green;
  font-weight: bold;
  margin: 15px 0 10px 0;
}
.testonondisponibile {
  color: red;
  font-weight: bold;
  margin: 15px 0 10px 0;
}
.testotempilavorazione {
  color: #428ba9;
  font-weight: normal;
  margin: 15px 0 10px 0;
}
.lazyimg {
  max-width: 350px;
  height: 350px;
  /*display: block;*/
  background: #F1F1FA;
  min-height: 150px;
  max-height: 350px;
  width: 100%;
  height: auto;
}
.boxhome {
  border: solid 1px #dcdcdc;
  padding-bottom: 15px;
}
.boxhome img {
  margin-bottom: 15px;
}
.grayfullwidth {
  background-color: #f7f7f7;
  width: 100%;
}
.card-simple {
  margin-bottom: 15px;
}
.card-simple .card-description {
  font-size: 14px;
  text-align: justify;
}
.bthomecategorie {
  border: solid 1px #428ba9;
  padding: 20px 30px 20px 30px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  color: #428ba9 !important;
  display: inline-block;
  border-radius: 5px;
}
.bthomecategorie:hover {
  /*background-color: #f8f8f8;*/
  -webkit-box-shadow: 0 0 10px #d0ebf5;
          box-shadow: 0 0 10px #d0ebf5;
  cursor: pointer;
}
.categorylisthome {
  margin-bottom: 30px;
  margin-top: 25px;
  display: none;
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
  padding-top: 30px;
  padding-bottom: 30px;
}
.categorylisthome .categorylisthome-item {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #575757 !important;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 10px;
  width: 100%;
}
.categorylisthome .categorylisthome-subitem {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #575757 !important;
  font-size: 13px;
  width: 100%;
}
.categorylisthome .btcategoryaltro {
  color: #dcdcdc !important;
}

@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v8/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsOdC6.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Anton Regular'), local('Anton-Regular'), url(https://fonts.gstatic.com/s/anton/v11/1Ptgg87LROyAm3Kz-Co.ttf) format('truetype');
}
.img-inline-content {
  width: 50%;
  margin: 0 30px 30px 0;
  float: left;
  border: solid 1px #dcdcdc;
}
@media screen and (max-width: 450px) {
  .img-inline-content {
    float: none;
    width: 100%;
    margin: 0 auto 30px auto;
  }
}
.filelink {
  display: inline-block;
  padding: 10px 10px 10px 50px;
  color: gray;
  font-size: 14px;
}
.filelink.filepdf {
  background-image: url("/images/icofiles/pdf.png");
  background-repeat: no-repeat;
  background-position: 5px 5px;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-top-55 {
  margin-top: 55px !important;
}
.margin-bottom-55 {
  margin-bottom: 55px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-top-65 {
  margin-top: 65px !important;
}
.margin-bottom-65 {
  margin-bottom: 65px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-top-75 {
  margin-top: 75px !important;
}
.margin-bottom-75 {
  margin-bottom: 75px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-top-85 {
  margin-top: 85px !important;
}
.margin-bottom-85 {
  margin-bottom: 85px !important;
}
.margin-top-90 {
  margin-top: 90px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-top-95 {
  margin-top: 95px !important;
}
.margin-bottom-95 {
  margin-bottom: 95px !important;
}
.margin-top-100 {
  margin-top: 100px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}
.pointer {
  cursor: pointer;
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.validationerror {
  background-color: ivory !important;
  background-image: url(/images/common/alert.png);
  background-position: right;
  background-repeat: no-repeat;
}
.oscura {
  display: none;
  z-index: 9998;
  background-image: url('/images/admin/common/oscura.png');
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.messagebox {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  z-index: 9999;
  border-radius: 3px;
  border: solid 1px #dcdcdc;
  -webkit-box-shadow: 0 0 15px gray;
          box-shadow: 0 0 15px gray;
  max-height: 95%;
  overflow-y: auto;
}
.messagebox .messageboxicon {
  text-align: center;
  margin: 10px 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
}
.messagebox .messageboxicon.iconalert {
  background-image: url("/images/admin/common/min_alert.png");
  height: 30px;
  margin-bottom: 20px;
}
.messagebox .messageboxicon.iconinfo {
  background-image: url("/images/admin/common/min_info.png");
  height: 30px;
  margin-bottom: 20px;
}
.messagebox .messageboxicon.iconerror {
  background-image: url("/images/admin/common/min_error.png");
  height: 30px;
  margin-bottom: 20px;
}
.messagebox .messageboxicon.iconconfirm {
  background-image: url("/images/admin/common/min_confirm.png");
  height: 30px;
  margin-bottom: 20px;
}
.messagebox .messageboxicon.iconloading {
  background-image: url("/images/ajax/loader.gif");
  height: 30px;
  margin-bottom: 20px;
}
.messagebox .messageboxcontent {
  margin: 10px 0 20px 0;
  text-align: center;
}
.messagebox .messageboxbuttons {
  padding: 10px 0 0px 0;
  text-align: center;
}
.emptysearch {
  padding: 20px;
  text-align: center;
  border: dashed 1px gray;
  font-size: 18px;
}
.btn-goup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  background-image: url("/images/common/goup.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: none;
  z-index: 999999;
}
.loading {
  background-image: url("/images/ajax/loader.gif");
  background-repeat: no-repeat;
  background-position: 5px 3px;
  padding-left: 40px;
}
